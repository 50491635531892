import { Dialog } from '../../../ui/overlays/dialog/dialog';
import { DialogHeader } from '../../../ui/overlays/dialog/dialog-header';
import { Trans } from '../../../i18n/trans';
import { DialogBody } from '../../../ui/overlays/dialog/dialog-body';
import { DialogFooter } from '../../../ui/overlays/dialog/dialog-footer';
import { Button } from '../../../ui/buttons/button';
import { useDialogContext } from '../../../ui/overlays/dialog/dialog-context';
import { useForm } from 'react-hook-form';
import { Subscription } from '../../../billing/subscription';
// import {UpdateSupportWithdrawsPayload, useUpdateSupportWithdraws} from './requests/use-update-supportwithdraws';
// import {CrupdateSupportWithdrawsForm} from './crupdate-supportwithdraws-form';
import { SupportPayments } from '@common/billing/support-payments';
import { CrupdateSupportWithdrawsForm } from '@common/admin/support/crupdate-supportwithdraws-form';
import { CrupdateBalanceWithdrawsForm } from './crupdate-balancewithdraws-form';
import { UpdateSupportWithdrawsPayload } from '@common/admin/support/requests/use-update-supportwithdraws';
import { WithdrawSupportWithdrawsPayload, useWithdrawSupportWithdraws } from './requests/use-update-balancewithdraws';
import { Link, redirect } from 'react-router-dom';
import { usePointsBalance } from './requests/points-balance-use';
import { useAuth } from '@common/auth/use-auth';
import { useSettings } from '@common/core/settings/use-settings';
import { createSvgIconFromTree } from '@common/icons/create-svg-icon';
import { RedeemPointsPayload, useRedeemPoints } from './requests/use-redeem-points';
import { FormTextField } from '@common/ui/forms/input-field/text-field/text-field';
import { useState } from 'react';
import { useIsMobileMediaQuery } from '@common/utils/hooks/is-mobile-media-query';
import { StarsIcon } from '@common/icons/material/Stars';

interface RedeemPointsDialogProps {
  artistId: number;
}

export const removeQueryParam = (paramName: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(paramName);
  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
};

const globalStyle = `
  @media (min-width: 768px) {
      
  }
  @media only screen and (max-width: 320px) {
      .firstDiv {
        display: grid !important;
      }
      .secondDiv {
        display: block !important;
        margin-top: 10px !important;
      }
      .firstBtn {
        width: 100% !important;
      }
  }
`;

export function RedeemPointsDialog({
  artistId,
}: RedeemPointsDialogProps) {
  const isMobile = useIsMobileMediaQuery();
  const settings = useSettings();
  const { user } = useAuth();
  const myPayloadPoints = {
    userId: user?.id || 0,
  };
  const queryPoints = usePointsBalance(myPayloadPoints);
  const balancePoints = queryPoints.data?.pagination.data[0].points || 0;

  const form = useForm<RedeemPointsPayload>();
  const { mutate: redeemPoints, isLoading } = useRedeemPoints(form);
  const [showInstructionsGlobal, setShowInstructionsGlobal] = useState(false);
  return (
    <><style>{globalStyle}</style><Dialog size="xl" className='withdrawdialog'>
      <DialogHeader hideDismissButton={true}>
        <Trans message="Redeem points" />
      </DialogHeader>
      <DialogBody>
        <h3 style={{ display: 'flex', placeContent: 'center', fontWeight: 'bold', paddingBottom: '5px', textAlign: 'center', fontSize: '15px', alignItems: 'center' }} className='text-primary'>Available points: 
          <StarsIcon width={18} height={18} className='ml-3' /> {balancePoints}</h3>
        <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
          <hr style={{ width: '50px' }} />
        </div>

        <div style={{ marginTop: '14px' }}>
          {settings?.pointssystem?.redeemItems?.map((redeemItem, index) => {
            const Icon = redeemItem.icon && createSvgIconFromTree(redeemItem.icon);
            // const [isLoading, setIsLoading] = useState(false);
            const [showInstructions, setShowInstructions] = useState(false);
            const [redeemInstructions, setRedeemInstructions] = useState('');

            const handleRedeemClick = () => {
              // redeemPoints({ user_id: String(user?.id || 0), redeem_name: String(redeemItem.redeemItemName), redeem_value: Number(redeemItem.redeemItemValue) });
              setShowInstructions(true);
              setShowInstructionsGlobal(true);
              // setIsLoading(true);
            };

            const handleInstructionsChange = (value: string) => {
              setRedeemInstructions(value);
            };

            return (
              <div key={index}>
                <div style={{ display: 'flex', placeContent: 'space-between', padding: '14px 14px' }} className='firstDiv'>
                  <h1 style={{ display: 'flex' }}>
                    <div style={{ float: 'left', marginRight: '12px' }}>
                      {Icon && <Icon style={{ fontSize: isMobile ? '50px' : '80px', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', borderRadius: '3px', padding: '2px' }} className='shadow text-primary' />}
                    </div>
                    <span style={{
                      fontWeight: 'bold',
                      width: '70%',
                      display: 'block',
                      lineHeight: '1.4',
                      float: 'left',
                      marginTop: '-2px',
                      fontSize: isMobile ? '14px' : '20px'
                    }}>{redeemItem.redeemItemName}</span></h1>
                  <div style={{
                    display: 'grid',
                    placeContent: 'center',
                    alignItems: 'center'
                  }}
                    className='secondDiv'>
                    <Button size={isMobile ? 'xs' : 'md'} variant="flat" color="primary"
                      // disabled={isLoading}
                      // onClick={() => {
                      //   redeemPoints({ user_id: String(user?.id || 0), redeem_name: String(redeemItem.redeemItemName), redeem_value: Number(redeemItem.redeemItemValue) });
                      // }}
                      disabled={showInstructions || showInstructionsGlobal}
                      onClick={handleRedeemClick}
                      className='firstBtn items-center'
                    >
                      <Trans message='Redeem' />
                      <StarsIcon width={18} height={18} className='ml-3' />
                      {redeemItem.redeemItemValue}
                    </Button>
                  </div>
                </div>
                <div style={{ padding: '0px 14px', display: showInstructions ? 'block' : 'none' }} id={`redeem_details_instructions_div_${index}`}>
                  <div className="mb-30 flex-auto text-sm">
                    <label className="block first-letter:capitalize text-left whitespace-nowrap text-sm mb-4">Redeem details or instructions</label>
                    <div className="isolate relative">
                      <textarea id={`redeem_details_instructions_${index}`} onChange={(e) => handleInstructionsChange(e.target.value)} name="redeem_details_instructions" rows={3} className="block text-left relative w-full appearance-none transition-shadow text bg-transparent rounded border-divider border focus:ring focus:ring-primary/focus focus:border-primary/60 focus:outline-none shadow-sm text-sm min-h-42 pl-12 pr-12 py-12"></textarea>

                      <div style={{ textAlign: 'right', marginTop: '8px' }}>
                        <Button size="md" variant="flat" color="chip"
                          disabled={!showInstructions}
                          onClick={() => {
                            setShowInstructions(false);
                            setShowInstructionsGlobal(false);
                          }}
                          style={{ marginRight: '6px' }}
                        >
                          <Trans message='Cancel' />
                        </Button>

                        <Button size="md" variant="flat" color="primary"
                          disabled={isLoading}
                          onClick={() => {
                            redeemPoints({ user_id: String(user?.id || 0), redeem_name: String(redeemItem.redeemItemName), redeem_value: Number(redeemItem.redeemItemValue), redeem_instructions: redeemInstructions });
                          }}
                        >
                          <div className='flex items-center'>
                            <Trans message='Submit' />
                            <span style={{ marginLeft: '6.5px' }}>(</span>
                            <StarsIcon width={18} height={18} />
                            {redeemItem.redeemItemValue})
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
                  <hr style={{ width: 'calc(100% - 28px)' }} />
                </div>
              </div>
            );
          })}



        </div>

      </DialogBody>
      <DialogFooter>

        <Link to="#">
          <Button
          >
            <Trans message="Cancel" />
          </Button>
        </Link>

      </DialogFooter>
    </Dialog></>
  );
}
